export default {
    en: {
        TopMenu: {
            Games: 'Games',
            MyLibrary: 'My Library',
            SignIn: 'SignIn',
            SignUp: 'SignUp',
            Logout: 'Logout'
        },
        Users: {
            Profile: 'Profile',
            Logout: 'Logout'
        },
        Games: {
            Ratings: 'Ratings',
            UserStatuses: {
                Owning: {
                    itemOnly: 'Game Only',
                    cib: 'CIB',
                    newAndSealed: 'New And Sealed',
                    gradedNew: 'Graded New',
                    gradedCIB: 'Graded CIB',
                    boxOnly: 'Box Only',
                    manualOnly: 'Manual Only',
                    itemAndBox: 'Game & Box',
                    itemAndManual: 'Game & Manual',
                    boxAndManual: 'Box & Manual'
                },
                Condition: {
                    noBlemishes: 'No Blemishes',
                    writing: 'Writing',
                    stickers: 'Stickers',
                    tornLabel: 'Torn Label',
                    scratches: 'Scratches',
                    notWorking: 'Not Working',
                    normalWear: 'Normal Wear'
                },
                Play: {
                    wishToHave: 'Wish To Have',
                    wishToPlay: 'Wish To Play',
                    brought: 'Brought',
                    backlog: 'Backlog',
                    played: 'Played',
                    completed: 'Completed',
                    fullyCompleted: 'Fully Completed'
                }
            }
        },
        Genres: {
            "indie": "Indie",
            "adventure": "Adventure",
            "simulator": "Simulator",
            "strategy": "Strategy",
            "role-playing-rpg": "Role-Playing",
            "puzzle": "Puzzle",
            "shooter": "Shooter",
            "arcade": "Arcade",
            "platform": "Platformer",
            "sport": "Sport",
            "racing": "Racing",
            "visual-novel": "Visual Novel",
            "fighting": "Fighting",
            "point-and-click": "Point and Click",
            "turn-based-strategy-tbs": "Turn Based Strategy",
            "hack-and-slash-beat-em-up": "Hack&Slash\\Beat 'em Up",
            "music": "Music",
            "card-and-board-game": "Card\\Board Game",
            "tactical": "Tactical",
            "real-time-strategy-rts": "Real Time Strategy",
            "quiz-trivia": "Quiz",
            "pinball": "Pinball",
            "moba": "Moba"
        },
        Platforms: {
            "win": "Windows",
            "mac": "Mac",
            "switch": "Switch",
            "ios": "iOS",
            "ps4--1": "Play Station 4",
            "linux": "Linux",
            "xboxone": "XBOX One",
            "android": "Android",
            "browser": "Browser",
            "ps3": "Play Station 3",
            "ps2": "Play Station 2",
            "arcade": "Arcade",
            "ps5": "Play Station 5",
            "xbox360": "XBOX 360",
            "dos": "DOS",
            "ps": "Play Station 1",
            "series-x": "XBOX SeriesX",
            "wii": "Wii",
            "c64": "c64",
            "nds": "nds",
            "gba": "gba",
            "amiga": "amiga",
            "psvita": "psvita",
            "psp": "psp",
            "3ds": "3ds",
            "zxs": "zxs",
            "acpc": "acpc",
            "nes": "nes",
            "wiiu": "wiiu",
            "handheld-electronic-lcd": "handheld-electronic-lcd",
            "atari-st": "atari-st",
            "sfam": "sfam",
            "smd": "smd",
            "gb": "gb",
            "xbox": "xbox",
            "snes--1": "snes--1",
            "mobile": "mobile",
            "gbc": "gbc",
            "appleii": "appleii",
            "msx": "msx",
            "famicom": "famicom",
            "saturn": "saturn",
            "ngc": "ngc",
            "n64": "n64",
            "atari8bit": "atari8bit",
            "nintendo-dsi": "nintendo-dsi",
            "atari2600": "atari2600",
            "dc": "dc",
            "steam-vr": "steam-vr",
            "plug-and-play": "plug-and-play",
            "sms": "sms",
            "bbcmicro": "bbcmicro",
            "satellaview": "satellaview",
            "pc-98": "pc-98",
            "turbografx16--1": "turbografx16--1",
            "gamegear": "gamegear",
            "playstation-vr": "playstation-vr",
            "stadia": "stadia",
            "winphone": "winphone",
            "oculus-quest": "oculus-quest",
            "snes": "snes",
            "oculus-vr": "oculus-vr",
            "oculus-quest-2": "oculus-quest-2",
            "3do": "3do",
            "pc-8801": "pc-8801",
            "genesis-slash-megadrive": "genesis-slash-megadrive",
            "segacd": "segacd",
            "fds": "fds",
            "sharp-x68000": "sharp-x68000",
            "turbografx-16-slash-pc-engine-cd": "turbografx-16-slash-pc-engine-cd",
            "trs-80": "trs-80",
            "colecovision": "colecovision",
            "neogeomvs": "neogeomvs",
            "intellivision": "intellivision",
            "vic-20": "vic-20",
            "philips-cd-i": "philips-cd-i",
            "neogeoaes": "neogeoaes",
            "fm-towns": "fm-towns",
            "playdate": "playdate",
            "c16": "c16",
            "oculus-rift": "oculus-rift",
            "amiga-cd32": "amiga-cd32",
            "x1": "x1",
            "fm-7": "fm-7",
            "apple-iigs": "apple-iigs",
            "wonderswan": "wonderswan",
            "ouya": "ouya",
            "meta-quest-2": "meta-quest-2",
            "acorn-electron": "acorn-electron",
            "neo-geo-cd": "neo-geo-cd",
            "windows-mixed-reality": "windows-mixed-reality",
            "ti-99": "ti-99",
            "wonderswan-color": "wonderswan-color",
            "neo-geo-pocket-color": "neo-geo-pocket-color",
            "lynx": "lynx",
            "atari5200": "atari5200",
            "msx2": "msx2",
            "sg1000": "sg1000",
            "ngage": "ngage",
            "philips-videopac-g7000": "philips-videopac-g7000",
            "new-nintendo-3ds": "new-nintendo-3ds",
            "c-plus-4": "c-plus-4",
            "blackberry": "blackberry",
            "atari7800": "atari7800",
            "jaguar": "jaguar",
            "acorn-archimedes": "acorn-archimedes",
            "trs-80-color-computer": "trs-80-color-computer",
            "game-and-watch": "game-and-watch",
            "evercade": "evercade",
            "virtualboy": "virtualboy",
            "amazon-fire-tv": "amazon-fire-tv",
            "dragon-32-slash-64": "dragon-32-slash-64",
            "watara-slash-quickshot-supervision": "watara-slash-quickshot-supervision",
            "psvr2": "psvr2",
            "leapster-explorer-slash-leadpad-explorer": "leapster-explorer-slash-leadpad-explorer",
            "vectrex": "vectrex",
            "sinclair-zx81": "sinclair-zx81",
            "arduboy": "arduboy",
            "nec-pc-6000-series": "nec-pc-6000-series",
            "odyssey--1": "odyssey--1",
            "sega32": "sega32",
            "windows-mobile": "windows-mobile",
            "leapster": "leapster",
            "zeebo": "zeebo",
            "amstrad-pcw": "amstrad-pcw",
            "astrocade": "astrocade",
            "cpet": "cpet",
            "dvd-player": "dvd-player",
            "sega-pico": "sega-pico",
            "fairchild-channel-f": "fairchild-channel-f",
            "gear-vr": "gear-vr",
            "pc-9800-series": "pc-9800-series",
            "visual-memory-unit-slash-visual-memory-system": "visual-memory-unit-slash-visual-memory-system",
            "ooparts": "ooparts",
            "game-dot-com": "game-dot-com",
            "vc-4000": "vc-4000",
            "1292-advanced-programmable-video-system": "1292-advanced-programmable-video-system",
            "sharp-mz-2200": "sharp-mz-2200",
            "pc-fx": "pc-fx",
            "pokemon-mini": "pokemon-mini",
            "mega-duck-slash-cougar-boy": "mega-duck-slash-cougar-boy",
            "blu-ray-player": "blu-ray-player",
            "gamate": "gamate",
            "legacy-computer": "legacy-computer",
            "epoch-cassette-vision": "epoch-cassette-vision",
            "neo-geo-pocket": "neo-geo-pocket",
            "supergrafx": "supergrafx",
            "leaptv": "leaptv",
            "pocketstation": "pocketstation",
            "pc-8800-series": "pc-8800-series",
            "tatung-einstein": "tatung-einstein",
            "daydream": "daydream",
            "playstation-vr2": "playstation-vr2",
            "vc": "vc",
            "playdia": "playdia",
            "plato--1": "plato--1",
            "thomson-mo5": "thomson-mo5",
            "casio-loopy": "casio-loopy",
            "vsmile": "vsmile",
            "odyssey-2-slash-videopac-g7000": "odyssey-2-slash-videopac-g7000",
            "zod": "zod",
            "nintendo-64dd": "nintendo-64dd",
            "commodore-cdtv": "commodore-cdtv",
            "duplicate-stadia": "duplicate-stadia",
            "pc-50x-family": "pc-50x-family",
            "hyper-neo-geo-64": "hyper-neo-geo-64",
            "nuon": "nuon",
            "hyperscan": "hyperscan",
            "airconsole": "airconsole",
            "microcomputer--1": "microcomputer--1",
            "hp2100": "hp2100",
            "intellivision-amico": "intellivision-amico",
            "ay-3-8610": "ay-3-8610",
            "ay-3-8500": "ay-3-8500",
            "oculus-go": "oculus-go",
            "onlive-game-system": "onlive-game-system",
            "palm-os": "palm-os",
            "pdp11": "pdp11",
            "ay-3-8760": "ay-3-8760",
            "microvision--1": "microvision--1",
            "meta-quest-3": "meta-quest-3",
            "call-a-computer": "call-a-computer",
            "pdp10": "pdp10",
            "epoch-super-cassette-vision": "epoch-super-cassette-vision",
            "nintendo-playstation": "nintendo-playstation",
            "ay-3-8606": "ay-3-8606",
            "pdp-8--1": "pdp-8--1",
            "pdp-7--1": "pdp-7--1",
            "nimrod": "nimrod",
            "hp3000": "hp3000",
            "ay-3-8605": "ay-3-8605",
            "exidy-sorcerer": "exidy-sorcerer",
            "imlac-pds1": "imlac-pds1",
            "gt40": "gt40",
            "donner30": "donner30",
            "pdp1": "pdp1",
            "analogueelectronics": "analogueelectronics",
            "edsac--1": "edsac--1",
            "sdssigma7": "sdssigma7",
            "sol-20": "sol-20",
            "cdccyber70": "cdccyber70",
            "ay-3-8710": "ay-3-8710",
            "ay-3-8607": "ay-3-8607",
            "ay-3-8603": "ay-3-8603",
            "sinclair-ql": "sinclair-ql"
        }
    }
}