
class StorageUtils {
    static setLocalStorageItem (name, value) {
        if (typeof localStorage === 'undefined') return false;
        localStorage.setItem(name, value);
    }
    static getLocalStorageItem (name) {
        if (typeof localStorage === 'undefined') return false;
        return localStorage.getItem(name);
    }
    static removeFromLocalStorageItem (name) {
        if (typeof localStorage === 'undefined') return false;
        return localStorage.removeItem(name);
    }
    static setSessionStorageItem (name, value) {
        if (typeof sessionStorage === 'undefined') return false;
        sessionStorage.setItem(name, value);
    }
    static getSessionStorageItem (name) {
        if (typeof sessionStorage === 'undefined') return false;
        return sessionStorage.getItem(name);
    }
    static removeFromSessionStorageItem (name) {
        if (typeof sessionStorage === 'undefined') return false;
        return sessionStorage.removeItem(name);
    }
}

export default StorageUtils;