import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { md3 } from 'vuetify/blueprints'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { createI18n } from 'vue-i18n'

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

const myCustomDarkTheme = {
  dark: false,
  colors: {
    background: '#121212',
    surface: '#FFFFFF',
    primary: '#1976D2',
    secondary: '#424242',
    accent: '#2ae5b2',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  },
}

// Ready translated locale messages
import messages from './i18n';

const i18n = new createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});


const vuetify = createVuetify({
    blueprint: md3,
    icons: {
      defaultSet: 'mdi'
    },
    theme: {
      defaultTheme: 'myCustomDarkTheme',
      themes: {
        myCustomDarkTheme,
      },
    },
    components,
    directives,
});

createApp(App).use(router).use(i18n).use(vuetify).mount('#app')
