<template>
  <v-app>
    <AppBar/>
    <v-main>
      <v-container>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import networkingController from './controllers/networkingController';
import authController from './controllers/authController';
import storageController from './controllers/storageController';
import gamesController from './controllers/gamesController';
import usersController from './controllers/usersController';

import AppBar from './components/AppBar.vue';

export default {
  name: 'App',
  provide: {
    networkingController,
    storageController,
    authController,
    gamesController,
    usersController
  },
  components: {
    AppBar
  },
  data() {
    return {
    }
  },
  mounted() {
  }
}
</script>
