<template>
    <v-autocomplete
        density="compact"
        hide-details
        clearable
        hide-no-data
        variant="outlined"
        autocomplete="nope"
        :items="searchAutocomplete"
        v-model="select"
        @update:search="onSearch"
        />
  </template>

  <script>
  export default {
    inject: {
      gameModel: {
        from: 'gamesController'
      }
    },
    data () {
      return {
        search: null,
        select: null,
        searchAutocomplete: []
      };
    },
    watch: {
        select(value) {
            this.onSearchChange(value);
        }
    },
    methods: {
        onSearchChange (value) {
            this.$router.push({ name: 'GamePage', params: { slug: value } });
        },
        onSearch (val) {
            if (!val || val.length < 2) return;

            this.gameModel.searchGames(val).then((results) => {
                this.searchAutocomplete = [];
                for (let i = 0; i < results.length; i++) {
                    this.searchAutocomplete.push({
                        title: results[i].title,
                        value: results[i].value
                    });
                }
            });
        }
    }
  };
  </script>