<template>
    <v-app-bar
      theme="dark"
      style="background: linear-gradient(to right, #12c2e9, #c471ed, #ff002f);"
      app
      flat
    >
    <!-- style="background: linear-gradient(45deg, #BB00C1, #0099B5)" -->
      <v-container class="py-0 fill-height">
        <h1 class="logo">
          TGG
        </h1>

        <v-btn text color="#2ae5b2" @click="$router.push('/games')">
          {{ $t('TopMenu.Games') }}
        </v-btn>

        <v-btn text color="#2ae5b2" @click="$router.push('/library')">
          {{ $t('TopMenu.MyLibrary') }}
        </v-btn>

        <v-spacer></v-spacer>

        <GameSearch/>
        <v-menu v-if="isLoggedIn">
          <template v-slot:activator="{ props }">
            <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
          </template>

          <v-list>
            <v-list-item>{{ userData.email }}</v-list-item>
            <v-list-item>{{ $t('Users.Profile') }}</v-list-item>
            <v-divider/>
            <v-list-item @click="onLogout">{{ $t('Users.Logout') }}</v-list-item>
          </v-list>
        </v-menu>
        <v-btn v-else @click="doAuth">
          {{ $t('TopMenu.SignIn') }}
        </v-btn>
      </v-container>
    </v-app-bar>
</template>

<style scoped>
  h1.logo {
    font-family: 'Roboto';
    font-weight: 900;
    letter-spacing: 4px;
    font-size: 50px;
    color: rgba(0,0,0,0);
  background: rgb(173,18,131);
    background: -webkit-linear-gradient(-0deg, #ff0ab1 0%,#ff002f 50%, #ff0ab1 100%);
    background: -moz-linear-gradient(-0deg, #ff0ab1 0%,#ff002f 50%, #ff0ab1 100%);
    background: linear-gradient(-0deg, #ff0ab1 0%,#ff002f 50%, #ff0ab1 100%);
    background-position: 1px 1px;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-animation: gradient 5s infinite;
    animation: gradient 5s infinite;
  }
  @-webkit-keyframes gradient {
    0% {
      font-size: 50px;
      background-position: 1px 1px;
    }
    25%{
      background-position: 200px 100px;
    }
    50% {
      background-position: 550px 100px;
    }
    75%{
      background-position: 200px 100px;
    }
    100% {
      font-size: 50px;
      background-position: 1px 1px;
    }
  }

  @keyframes gradient {
    0% {
      font-size: 50px;
      background-position: 1px 1px;
    }
    25%{
        background-position: 200px 100px;
    }
    50% {
        background-position: 550px 100px;
    }
    75%{
        background-position: 200px 100px;
    }
    100% {
        font-size: 50px;
        background-position: 1px 1px;
    }
  }
</style>

<script>
import GameSearch from './../views/Games/GameSearch.vue';

export default {
  name: 'AppBar',
  props: [],
  inject: {
    usersController: {
      from: 'usersController'
    },
    networkingController: {
      from: 'networkingController'
    },
    authController: {
      from: 'authController'
    }
  },
  components: {
    GameSearch
  },
  data: function () {
    return {
      userData: {}
    };
  },
  computed: {
    authUrl () {
      const apiHost = this.networkingController.state.apiHost;
      return process.env.NODE_ENV === 'production' ? apiHost + '/auth/google' : 'http://127.0.0.1:5001/auth/google';
    },
    isLoggedIn() {
        return this.userData.email;
    }
  },
  mounted () {
    this.usersController.getUserData().then( () => {
      this.userData.email = this.usersController.state.email;
    });
  },
  methods: {
    doAuth () {
      window.location.href = this.authUrl;
    },
    onLogout() {
      this.authController.doLogout();
      window.location.href="/"
    }
  }
};
</script>
