import { readonly } from "vue";
import { useCookies } from "vue3-cookies";
import axios from 'axios';

const state = {
    apiHost: process.env.NODE_ENV === 'production' ? 'https://api.thegameglitch.com' : 'http://127.0.0.1:5001'
    //host: 'http://127.0.0.1:5001',
    //host: 'https://api.thegameglitch.com',
};

const get = (request) => {
    console.log("-->", request);
    const cookie = useCookies();
    const token = cookie.cookies.get('tgg');
    return new Promise((resolve, reject) => {
        axios.get(state.apiHost + request, {
        headers: {
            'Authorization': token
        }
      }).then((response) => {
        console.log("<--", response.data);
        resolve(response.data);
      }, (error) => {
        if (error.status === 401) {
          this.logout();
        }
        reject(error);
      });
    });
};

const post = (request, body) => {
    console.log("-->", request, body);
    const cookie = useCookies();
    const token = cookie.cookies.get('tgg');
    return new Promise((resolve, reject) => {
      axios.post(state.apiHost + request, body, {
        headers: {
          'Authorization': token
        }
      }).then((response) => {
        console.log("<--", response.data);
        resolve(response.data);
      }, (error) => {
        if (error.status === 401) {
          //this.logout();
        }
        reject(error);
      });
    });
};


export default { state: readonly(state), get, post };