import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/', name: 'GamesList', component: () => import('../views/Games/GameList') },
  { path: '/auth/:token', name: 'authentication', component: () => import('../components/UserAuth') },
  { path: '/games/platform/:platform', name: 'GamesListByPlatrorm', component: () => import('../views/Games/GameList') },
  { path: '/game/:slug', name: 'GamePage', component: () => import('../views/Games/GamePage') },
  { path: '/library', name: 'MyLibrary', component: () => import('../views/Library/MyLibrary') },
  { path: '/account', name: 'AccountPage', component: () => import('../views/UserAccount/Index') }
  // will match everything and put it under `$route.params.pathMatch`
  // { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
  // will match anything starting with `/user-` and put it under `$route.params.afterUser`
  // { path: '/user-:afterUser(.*)', component: UserGeneric },
  // { path: ':pathMatch(.*)*', name: 'defaultPath', component: () => import('./views/Errors/404.vue') }
];

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router