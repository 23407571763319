import { reactive, readonly } from "vue";
import networking from "./networkingController";

const state = reactive({
  id: '',
  email: '',
  lists: {}
});

const getUserData = () => {
    return networking.get('/api/users/data').then( response => {
        if (response.status === 200) {
            state.id = response.result._id;
            state.email = response.result.email;
            state.lists = response.result.lists;
        }
    });
}

const addToList = (gameSlug) => {
    return networking.post('/api/users/addToList', {
        gameSlug: gameSlug
    }).then( response => {
        // if (response.status === 200) {
        // }
    });
}

const removeFromList = (gameSlug) => {
    return networking.post('/api/users/removeFromList', {
        gameSlug: gameSlug
    }).then( response => {
        // if (response.status === 200) {
        // }
    });
}

export default {
    state: readonly(state),
    getUserData,
    addToList,
    removeFromList
};