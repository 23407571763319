import { useCookies } from "vue3-cookies";

class AuthController {
    static doLogin (token) {
      const cookie = useCookies();
      cookie.cookies.set('tgg', token, '30d', '', '', true, 'Strict');
      document.location = '/';
    }

    static doLogout () {
      document.cookie = "tgg=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';";
    }
}

export default AuthController;