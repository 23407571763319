import { reactive, readonly } from "vue";
import networking from "./networkingController";

const state = reactive({
  items: [],
  pageNumber: 0,
  gameFields: {},
  searchResults: [],
  soldResults: [],
  owningStatuses: [
    'itemOnly',
    'cib',
    'newAndSealed',
    'gradedNew',
    'gradedCIB',
    'boxOnly',
    'manualOnly',
    'itemAndBox',
    'itemAndManual',
    'boxAndManual'
  ],
  conditionStatuses: [
    'noBlemishes',
    'writing',
    'stickers',
    'tornLabel',
    'scratches',
    'notWorking',
    'normalWear'
  ],
  playStatuses: [
    'wishToHave',
    'wishToPlay',
    'brought',
    'backlog',
    'played',
    'completed',
    'fullyCompleted'
  ]
});

const getGamesList = (filters = {}) => {
    return networking.post('/api/games/list', {
        filters: filters,
        pageNumber: state.pageNumber
    }).then( response => {
        if (response.status === 200) {
            if (state.pageNumber > 0) {
                state.items = state.items.concat(response.result);
            }
            else {
                state.items = response.result;
            }
            response.result.forEach(item => {
                state.lastLoadedID = item._id
            })
        }
    });
}

const setNextPage = () => {
    state.pageNumber += 1;
}

const resetPage = () => {
    state.pageNumber = 0;
}

const getGamesBySlug = (slug) => {
    return new Promise(resolve => {
        networking.get('/api/games/get/' + slug).then( response => {
            if (response.status === 200) {
                state.gameFields = response.result;
            }
            resolve(response);
        });
    });
}

const searchGames = (query) => {
    return new Promise(resolve => {
        networking.get('/api/games/search/' + encodeURIComponent(query)).then( response => {
            if (response.status === 200) {
                resolve(response.result);
                return;
            }
            resolve([]);
        });
    });
}

const getFilterPlatformsData = (query) => {
    return new Promise(resolve => {
        networking.get('/api/games/filters/platforms').then( response => {
            if (response.status === 200) {
                resolve(response.result);
                return;
            }
            resolve([]);
        });
    });
}

const getFilterGenresData = (query) => {
    return new Promise(resolve => {
        networking.get('/api/games/filters/genres').then( response => {
            if (response.status === 200) {
                resolve(response.result);
                return;
            }
            resolve([]);
        });
    });
}

const updateStatuses = (gameSlug, gameStatusesListName, gameStatusesListData) => {
    return networking.post('/api/games/updateStatuses', {
        gameSlug: gameSlug,
        gameStatusesListName: gameStatusesListName,
        gameStatusesListData: gameStatusesListData
    }).then( response => {
        if (response.status === 200) {
            console.log('status updated');
        }
    });
}

const getOffersFromOutside = (gameName) => {
    networking.post('/api/games/getOffersFromOutside', {
        gameName: gameName
    }).then( response => {
        if (response.status === 200) {
            state.soldResults = response.result;
        }
    });
}

export default {
    state: readonly(state),
    getGamesList,
    resetPage,
    setNextPage,
    getGamesBySlug,
    searchGames,
    getFilterPlatformsData,
    getFilterGenresData,
    updateStatuses,
    getOffersFromOutside
};